/* eslint-disable no-inner-declarations */
/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState, useEffect, memo } from 'react';
import axios from 'axios';

// Components
import { PrimaryButton, DefaultButton } from 'ui/Button';

const isBrowser = typeof window !== 'undefined';
const token = isBrowser ? localStorage.getItem('veezu_token') : '';

function LegalPDF({ successCb, declineCb, token: propsToken, userId }) {
  // Endpoint
  const apiUrl = process.env.GATSBY_API_ENDPOINT;
  // Token Value
  let tokenToUse = '';
  if (propsToken && propsToken !== '') {
    tokenToUse = propsToken;
  } else {
    tokenToUse = token;
  }
  // Headers
  const headers = {
    Authorization: `Bearer ${tokenToUse}`
  };
  const [pdfUrl, setPdfUrl] = useState(null);
  const [stream, setStream] = useState(null);
  const [currentPDF, setCurrentPDF] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch Profile
  useEffect(() => {
    async function fetchProfile() {
      const response = await fetch(`${apiUrl}/profile`, { headers });
      const profile = await response.json();
      setProfile(profile);
    }

    fetchProfile();
  }, []);

  // Check The Legal Documents Status either exists and accepted or not from the profile state
  useEffect(() => {
    if (!profile) return;
    // Check If Terms Exists and Accepted or Not
    const isTermsExists = profile?.options?.legal?.termsConditions?.identity !== '';
    const isTermsAccepted = !!profile?.metadata?.legal?.termsConditionsAccepted?.identity;
    if (isTermsExists && !isTermsAccepted) {
      return setCurrentPDF({
        name: 'Terms & Conditions',
        fileId: profile?.options?.legal?.termsConditions?.identity
      });
    }
    // Check If Privacy Policy Exists and Accepted or Not
    const isPrivacyPolicyExists = profile?.options?.legal?.privacyPolicy?.identity !== '';
    const isPrivacyPolicyExistsAccepted = !!profile?.metadata?.legal?.privacyPolicyAccepted?.identity;
    if (isPrivacyPolicyExists && !isPrivacyPolicyExistsAccepted) {
      return setCurrentPDF({
        name: 'Privacy Policy',
        fileId: profile?.options?.legal?.privacyPolicy?.identity
      });
    }
    // If None of the Above Condition are Satified, All Documents are accepted
    return setCurrentPDF({
      name: 'All Accepted',
      fileId: ''
    });
  }, [profile]);

  // Stream the PDF File from the currentPDF File ID
  useEffect(() => {
    if (!currentPDF) return;
    if (currentPDF.fileId === '') return;
    async function startStream() {
      const response = await fetch(`${apiUrl}/helper/legal/${currentPDF.fileId}/file`, {
        headers: { Authorization: `Bearer ${tokenToUse}` }
      });
      const stream = response.body.getReader();
      setStream(stream);
    }
    startStream();
  }, [currentPDF]);

  // Read the Stream and Create PDF from Blob and Set the PDF State
  useEffect(() => {
    async function readStream() {
      if (!stream) return;

      const chunks = [];
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await stream.read();
        if (done) {
          console.log('Stream ended');
          const blob = new Blob(chunks, { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);
          setPdfUrl(pdfUrl);
          break;
        }
        chunks.push(value);
      }
    }

    readStream();
  }, [stream]);

  useEffect(() => {
    if (!currentPDF) return;
    if (currentPDF.tcAccepted && currentPDF.ppAccepted) {
      localStorage.setItem('veezu_token', tokenToUse);
      if (userId !== '') {
        localStorage.setItem('veezu_user_id', userId);
      }
      successCb();
    }
  }, [currentPDF]);

  // Handle File Accept
  const handleAccept = async () => {
    setIsSubmitting(true);
    if (currentPDF.name === 'Terms & Conditions') {
      await acceptTermsAndConditions();
    } else {
      await acceptPrivacyPolicy();
    }
    setIsSubmitting(false);
  };

  // Handle File Decline
  const handleDecline = () => {
    if (currentPDF.name === 'Terms & Conditions') {
      declineTermsAndConditions();
    } else {
      declinetPrivacyPolicy();
    }
  };

  // Accept Terms and Conditions Handler
  const acceptTermsAndConditions = async () => {
    const { status } = await axios({
      baseURL: `${apiUrl}/termsconditions/accept`,
      method: 'POST',
      headers,
      data: {}
    });
    if (status === 200) {
      // Check If Privacy Policy Exists and Accepted or Not
      const isPrivacyPolicyExists = profile?.options?.legal?.privacyPolicy?.identity !== '';
      const isPrivacyPolicyExistsAccepted = !!profile?.metadata?.legal?.privacyPolicyAccepted?.identity;
      if (isPrivacyPolicyExists && !isPrivacyPolicyExistsAccepted) {
        return setCurrentPDF({
          name: 'Privacy Policy',
          fileId: profile?.options?.legal?.privacyPolicy?.identity,
          tcAccepted: true
        });
      }
      return setCurrentPDF({
        name: 'All Accepted',
        fileId: '',
        tcAccepted: true,
        ppAccepted: true
      });
    }
  };

  // Decline Terms and Conditions Handler
  const declineTermsAndConditions = async () => {
    const { status } = await axios({
      baseURL: `${apiUrl}/termsconditions/decline`,
      method: 'POST',
      headers,
      data: {}
    });
    if (status === 200) {
      return declineCb();
    }
  };

  // Accept Privacy Policy Handler
  const acceptPrivacyPolicy = async () => {
    const { status } = await axios({
      baseURL: `${apiUrl}/privacypolicy/accept`,
      method: 'POST',
      headers,
      data: {}
    });
    if (status === 200) {
      // Do Something
      return setCurrentPDF({
        name: 'All Accepted',
        fileId: '',
        tcAccepted: true,
        ppAccepted: true
      });
    }
  };

  // Decline Privacy Policy Handler
  const declinetPrivacyPolicy = async () => {
    const { status } = await axios({
      baseURL: `${apiUrl}/privacypolicy/decline`,
      method: 'POST',
      headers,
      data: {}
    });
    if (status === 200) {
      return declineCb();
    }
  };

  // Download File Handler
  const downloadHandler = () => {
    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = currentPDF?.name || 'File';
    a.click();
    // URL.revokeObjectURL(pdfUrl);
  };

  return (
    <div className="bg-gray-200 w-[90vw] h-[95vh] md:h-[90vh] max-w-screen-xl p-4 md:p-8 flex flex-col justify-center">
      <h2 className="h4-mobile md:h5-desktop mb-2">{currentPDF?.name}</h2>
      {currentPDF?.fileId !== '' && pdfUrl ? (
        <>
          <div className="flex-1 mb-4">
            <iframe
              title={currentPDF.name}
              key={pdfUrl}
              src={`${pdfUrl}#toolbar=0`}
              width="100%"
              height="100%"
              allowFullScreen
            />
          </div>
          <div className="md:flex md:flex-row-reverse justify-between items-center">
            <div className="md:flex justify-end items-center mb-10 md:md-4">
              <PrimaryButton
                type="button"
                text="Accept"
                className="mb-2 block w-full md:w-auto md:mb-0 md:mr-2"
                onClick={handleAccept}
                disabled={isSubmitting}
              />
              <DefaultButton type="button" text="Decline" className="block w-full md:w-auto" onClick={handleDecline} />
            </div>
            <PrimaryButton
              type="button"
              text="Download"
              className="mb-2 md:mb-10 block w-full md:w-auto"
              onClick={downloadHandler}
            />
          </div>
        </>
      ) : (
        <div className="flex-1 flex flex-col">
          <p className="flex-1">
            {currentPDF?.fileId === '' ? 'There is nothing to accept/decline.' : 'Loading PDF...'}
          </p>
          <PrimaryButton type="button" text="Close" onClick={() => successCb()} className="self-end" />
        </div>
      )}
    </div>
  );
}

export default memo(LegalPDF);

LegalPDF.propTypes = {
  successCb: PropTypes.func,
  declineCb: PropTypes.func
};
LegalPDF.defaultProps = {
  successCb: () => {},
  declineCb: () => {}
};
